import { Box, Typography, useMediaQuery } from "@mui/material"
import Lottie from "react-lottie-player"
import * as leftParticles from '../assets/JSON/JSON_Particles_L.json'
import * as rightParticles from '../assets/JSON/JSON_Particles_R.json'
import Container from "./Common/Container"


const stats = [
    {
        icon: 'images/intro/Installs.svg',
        value: '50,000,000',
        label: 'total installs'
    },
    {
        icon: 'images/intro/Coin_small.svg',
        value: '$800,000',
        label: 'MONTHLY REVENUE'
    },
    {
        icon: 'images/intro/Friend_invited_small_filled.svg',
        value: '2,600,000',
        label: 'monthly active users'
    },
]

const Intro = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');
    return (
        isMobile ? <Box
            sx={{
                boxSizing: 'border-box',
                backgroundImage: 'url(images/intro/bg_mob.svg)',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '-74px bottom',
                backgroundSize: 'auto 110%',
                padding: '100px 20px 0',
                overflowX: 'hidden',
                position: 'relative',
                '&::after': {
                    content:'""',
                    position:'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    height: '40px',
                    background: 'linear-gradient(to top, rgba(9, 9, 9, 1) 0%, rgba(9, 9, 9, 0) 20px)',
                }
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Superstar',
                    textTransform: 'uppercase',
                    fontSize: '40px',
                    lineHeight: '40px',
                    margin: 'auto 0',
                    background: 'linear-gradient(90deg, #FFEB33 0%, #62FF72 50%, #00FFFE 100%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textFillColor: 'transparent',
                    textAlign: 'center',
                    marginBottom: '40px'
                }}
            >
                Next level web3<br /> gaming: fun games <br /> and community<br /> engagement
            </Typography>
            <Box
                sx={{
                    // background: 'linear-gradient(to top, rgba(9, 9, 9, 1) 0%, rgba(9, 9, 9, 0) 40px)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '30px',
                    marginBottom: '60px'

                }}
            >
                {stats.map(item => <Box
                    key={item.label}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <img src={item.icon} alt={item.label} height={80} />
                    <Typography
                        sx={{
                            fontFamily: 'Superstar',
                            fontSize: '40px',
                            lineHeight: 'normal',
                            color: '#FFFFFF',
                            textAlign: "center",
                            marginTop: '15px'
                        }}
                    >
                        {item.value}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Superstar',
                            fontSize: '20px',
                            lineHeight: 'normal',
                            color: '#FFFFFF',
                            textAlign: "center",
                            opacity: '0.4'
                        }}
                    >
                        {item.label}
                    </Typography>
                </Box>)}
            </Box>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: '335px',
                    margin: '0 auto',
                    height: '10px',
                    backgroundColor: '#FFFFFF',
                    opacity: '.2',
                    position: 'relative',
                    zIndex: '1'
                }}
            />

        </Box>
            :
            <Box
                sx={{
                    boxSizing: 'border-box',
                    backgroundImage: 'url(images/intro/bg.svg)',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    padding: '184px 0 0',
                    overflowX: 'hidden'
                }}
            >
                <Container>
                    {/* portals */}
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '168px 1fr 168px',
                        gap: '36px',
                        marginBottom: '20px',
                        position: 'relative',

                    }}>
                        <Box
                            sx={{
                                width: "310px",
                                height: '330px',
                                display: 'flex',
                                backgroundImage: 'url(images/intro/left_shadow.svg)',
                                backgroundRepeat: 'no-repeat',
                                position: 'absolute',
                                left: 4,
                                transform: 'translateX(-100%)'
                            }}
                        >
                            <Lottie
                                rendererSettings={{
                                    preserveAspectRatio: 'xMinYMid slice',
                                }}
                                play={true}
                                animationData={leftParticles}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </Box>
                        <img width="168px" height="330px" src="images/intro/portal_left.svg" alt="decoration"></img>


                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                textTransform: 'uppercase',
                                fontSize: '80px',
                                lineHeight: '80px',
                                margin: 'auto 0',
                                background: 'linear-gradient(90deg, #FFEB33 0%, #62FF72 50%, #00FFFE 100%)',
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                textFillColor: 'transparent',
                                textAlign: 'center',
                            }}
                        >
                            Next level web3 gaming: fun games and community engagement

                        </Typography>
                        <img width="168px" height="330px" src="images/intro/portal_right.svg" alt="decoration" />
                        <Box
                            sx={{
                                width: "310px",
                                height: '330px',
                                display: 'flex',
                                backgroundImage: 'url(images/intro/right_shadow.svg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                position: 'absolute',
                                right: 4,
                                transform: 'translateX(100%)'
                            }}
                        >
                            <Lottie
                                rendererSettings={{
                                    preserveAspectRatio: 'xMinYMid slice',
                                }}
                                play={true}
                                animationData={rightParticles}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </Box>
                    </Box>
                    {/*stats*/}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            borderBottom: '10px solid #FFFFFF33',
                            gap: "20px",
                            margin: '0 auto',
                            '& > :nth-child(2)': {
                                borderTopColor: '#FFFFFF33',
                                '&::before': {
                                    content: "''",
                                    position: 'absolute',
                                    display: 'block',
                                    width: '10px',
                                    left: '-5px',
                                    bottom: '0',
                                    height: '87px',
                                    backgroundImage: 'url(images/intro/vertical_divider.svg)',
                                },
                                '&::after': {
                                    content: "''",
                                    position: 'absolute',
                                    display: 'block',
                                    width: '10px',
                                    right: '-5px',
                                    bottom: '0',
                                    height: '87px',
                                    backgroundImage: 'url(images/intro/vertical_divider.svg)',

                                }
                            }

                        }}
                    >
                        {
                            stats.map((item, index) => <Box
                                sx={{
                                    padding: '60px 0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'cetner',
                                    flexDirection: 'column',
                                    borderTop: '10px solid transparent',
                                    position: 'relative',

                                }}
                                key={item.label}
                            >
                                <img src={item.icon} height={'80px'} alt={item.label} />
                                <Typography
                                    sx={{
                                        fontFamily: 'Superstar',
                                        fontSize: '64px',
                                        lineHeight: 'normal',
                                        color: '#FFFFFF',
                                        textAlign: "center",
                                        marginTop: '15px'
                                    }}
                                >
                                    {item.value}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Superstar',
                                        fontSize: '32px',
                                        lineHeight: 'normal',
                                        color: '#FFFFFF',
                                        textAlign: "center",
                                        marginTop: '15px',
                                        opacity: '0.4'
                                    }}
                                >
                                    {item.label}
                                </Typography>
                            </Box>)
                        }

                    </Box>
                </Container>
            </Box >

    )
}
export default Intro